<template>
  <div>
    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-layout
        justify-center
        wrap
      >
        <v-flex
          md12
        >
          <material-card
            color="green"
            title="Категории товаров"
            text=""
          >
            <v-data-table
              :headers="headers"
              :items="items"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td style="max-width=40px">
                  <v-btn color="primary" small flat icon @click="pickToEditCategory(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn color="error" small flat icon @click="pickToDeleteCategory(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
                 <td > {{ item.sort }}</td>
                <td > {{ item.name }}</td>
                <td>
                  <span v-if="!item.restricted">для всех</span>
                  <span v-if="item.restricted">ограничен</span>
                </td>
              </template>
            </v-data-table>
          </material-card>
        </v-flex>
        
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-layout row wrap>
          <v-btn color="#66bb6a" @click="addCategoryDialog = true">Добавить</v-btn>
      </v-layout>
    </v-container>
    <v-dialog v-model="addCategoryDialog" max-width="600">
      
        <v-card>
            <v-toolbar flat color="#66bb6a">
              <v-toolbar-title left color="blue" class="text--white">Новая категория</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="addCategoryDialog = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          <v-card-text>
            <v-alert
              :value="error != null"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-form ref="form" v-model="valid">       
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Название</v-flex>
                <v-flex xs8>
                  <v-text-field :rules="rules.required"
                                label="Название" 
                                v-model="newCategory.name" 
                                ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs4 class="font-weight-bold">Сортировка</v-flex>
                <v-flex xs8>
                    <v-text-field :rules="rules.required"
                                  label="Сортировка" 
                                  v-model="newCategory.sort" 
                                  number
                                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Родительский контроль</v-flex>
                  <v-flex xs8>
                    <v-checkbox
                      v-model="newCategory.restricted"
                      label="Ограничить доступ к этой категории"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              
              <v-layout wrap>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" @click="addCategory" class="white--text" :disabled="!valid">Сохранить</v-btn>
                  <v-btn color="red darken-1" @click.native="addCategoryDialog = false" class="white--text">Отмена</v-btn>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="editCategoryDialog" max-width="600">
        <v-card>
            <v-toolbar flat color="#66bb6a">
              <v-toolbar-title left color="blue" class="text--white">Редактирование Категории</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="editCategoryDialog = false">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          <v-card-text>
            <v-alert
              :value="error != null"
              type="error"
            >
              {{ error }}
            </v-alert>
            <v-form ref="form" v-model="valid">                          
                <v-layout wrap>
                    <v-flex xs4 class="font-weight-bold">Название</v-flex>
                    <v-flex xs8>
                        <v-text-field :rules="rules.required"
                                      label="Название" 
                                      v-model="editCategory.name" 
                                      ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                    <v-flex xs4 class="font-weight-bold">Сортировка</v-flex>
                    <v-flex xs8>
                        <v-text-field :rules="rules.required"
                                      label="Сортировка" 
                                      v-model="editCategory.sort" 
                                      number
                                      ></v-text-field>
                    </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Родительский контроль</v-flex>
                  <v-flex xs8>
                    <v-checkbox
                      v-model="editCategory.restricted"
                      label="Ограничить доступ к этой категории"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" @click="updateCategory" class="white--text" :disabled="!valid">Сохранить</v-btn>
                    <v-btn color="red darken-1" @click.native="editCategoryDialog = false" class="white--text">Отмена</v-btn>
                </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="deleteCategoryDialog" max-width="600">
    
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Удаление категории</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="deleteCategoryDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-layout wrap>
            <div>Вы действительно хотите удалить категорию "{{ editCategory.name }}"? </div><br><br>
            <div v-if='shopItems'>Следующие товары будут перенесены в категорию "Разное":<br>
              <div v-for="item in shopItems" :key="item.id">- {{ item.name }}<br></div>
            </div>
          </v-layout>
          <v-layout wrap>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" @click="deleteCategory" class="white--text" :disabled="!valid">Удалить</v-btn>
            <v-btn color="red darken-1" @click.native="deleteCategoryDialog = false" class="white--text">Отмена</v-btn>
          </v-layout>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    error: null,
    addCategoryDialog: false,
    editCategoryDialog: false,
    deleteCategoryDialog: false,
    headers: [
      {
        sortable: false,
        text: '',
        value: ''
      },
      {
        sortable: true,
        text: 'Сортировка',
        value: 'sort'
      },
      {
        sortable: true,
        text: 'Название',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Доступ',
        value: 'restricted'
      }
    ],
    items: [
      
    ],
    shopItems: [

    ],
    newCategory: {
      id: 0,
      name: null,
      sort: 0
    },
    editCategory:{},
    rules: {
        required: [v => !!v || 'Поле обязательно',],
    },
  }),
  computed: {
    shopItemNames(){
      console.log(this.shopItems)
      if(this.shopItems && this.shopItems.length > 0){
        return this.shopItems.reduce(
            function(accumulator, shopItem) {
              console.log(shopItem)
              return accumulator + shopItem.name + ' ';
            }, ''
          )
      }
      return '';
    }
  },
  mounted () {
    this.getCategories()
  },
  methods: {
    pickToEditCategory(item){
      this.editCategory = item;
      this.editCategoryDialog = true;
    },
    pickToDeleteCategory(item){
      this.editCategory = item;
      this.$http.get('/ShopItemCategory/' + item.id + '/shopitems')
        .then(response => {
          this.shopItems = response.data
          this.deleteCategoryDialog = true;
        })
    },
    getCategories (){
      this.$http.get('/ShopItemCategory')
        .then(response => {
          this.items = response.data
          console.log(this.items)
        })
    },
    addCategory(){
      this.error = null;
      this.$http.post('/ShopItemCategory', this.newCategory).
          then(response => {
            this.addCategoryDialog = false;
            this.getCategories();
          }).
          catch(e => {
            this.error = e.response.data            
            console.log(e)
          });
    },
    updateCategory(){
      this.error = null;
      this.$http.put('/ShopItemCategory/'+this.editCategory.id, this.editCategory).
          then(response => {
            this.editCategoryDialog = false;
            this.getCategories();
          }).
          catch(e => {
            this.error = e.response.data
            console.log(e)
          });
    },
    deleteCategory(){
      this.$http.delete('/ShopItemCategory/' + this.editCategory.id).
        then(response => {
          this.deleteCategoryDialog = false;
          this.getCategories();
        }).
        catch(e => {
            console.log(e)
        });
    }
  }
}
</script>
